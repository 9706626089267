.datepicker {
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  color: #393838;
  -webkit-appearance: none;
  background-color: #ffffff;
  background-image: url('../image/calender.png');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position-x: 440px;
  background-position-y: center;
  cursor: pointer;
}

.datepicker:focus {
  outline: none;
}

.datepicker::placeholder {
  color: #8d8d8d;
}

.datepicker.error {
  border: 1px solid #dc0e0e;
  color: #dc0e0e;

}

.datepicker.error::placeholder {
  color: #dc0e0e;

}

.datepicker.success {
  border: 1px solid #16634f;
  color: #16634f;
}

.datepicker.success::placeholder {
  color: #16634f;
}

@media all and (min-width: 641px) {
  .datepicker {
    width: 476px;
    height: 56px;
    font-size: 18px;
    padding-left: 20px;
  }
}

@media all and (max-width: 640px) {
  .datepicker {
    width: 280px;
    height: 38px;
    font-size: 12px;
    padding-left: 10px;
  }
}